<template>
	<!-- loader -->
	<loader v-if="loading"> </loader>

	<!-- page -->
	<div
		class="container page py-5"
		v-else>
		<!-- light box -->
		<vue-easy-lightbox
			moveDisabled
			:visible="visible"
			:imgs="offerIndex.images.map((e) => e.url)"
			:index="index"
			@hide="handleHide"></vue-easy-lightbox>

		<!-- form dialog  -->
		<div
			v-if="openForm"
			class="form-dialog center-item"
			@click.self="openForm = false">
			<div
				class="form-close close-icon white-text fs-5 cp"
				@click="openForm = false"></div>
			<div class="form-container white-bg radius-md px-5 py-4 mx-3">
				<h2 class="mb-3 fs-2 fw-bold">Request a Quote</h2>
				<feedback-form
					:inputClass="'contact-us-inputs'"
					:massageBody="`I would like to request a quote for '${offerIndex.name}'.\n\nBest regards.`"
					:massageType="'request a quote'">
					<template v-slot:button="{ tabindex }">
						<button
							type="submit"
							class="button main-bg white-text"
							:tabindex="tabindex">
							Send
						</button>
					</template>
				</feedback-form>
			</div>
		</div>

		<!-- main section -->
		<section class="main-content row mb-5">
			<!-- image -->
			<div class="col-12 col-md-6 col-lg-5">
				<div class="main-image image image--cover my-3">
					<img
						:src="offerIndex.primaryImage.url"
						:alt="offerIndex.name" />
				</div>
			</div>

			<!-- descritption -->
			<div class="col-12 col-md-6 col-lg-7 position-relative">
				<div class="d-flex justify-content-between align-items-star">
					<h1 class="mb-0 w-75">
						{{ offerIndex.name }}
					</h1>
					<div class="offers-card__sale">
						<div class="offers-card__sale-top">UP TO</div>
						<div class="offers-card__sale-value">
							{{ offerIndex.code }}
						</div>
						<div class="offers-card__sale-bottom">OFF</div>
					</div>
				</div>
				<div
					v-html="offerIndex.descritption"
					class="offer-description"></div>
				<div
					class="button main-bg white-text mt-3 mb-4"
					@click="openForm = true">
					Request a Quote
				</div>
				<!-- more images -->
				<div
					class="more-images mb-5"
					v-if="offerIndex.images.length">
					<h2>more images</h2>
					<div class="more-images__contant">
						<div
							class="more-images__item image image--cover"
							:class="{
								'more-images__item--overflow':
									i === 3 && offerIndex.length > 4,
							}"
							style="--overflow-images: '5+'"
							v-for="(image, i) in offerIndex.images"
							:key="image.id"
							@click="
								index = i;
								visible = true;
							">
							<img
								:src="image.url"
								:alt="`${offerIndex.title}-image-${i}`" />
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- more products -->
		<section
			class="more-products"
			v-if="offers.filter((e) => e.slug !== offerIndex.slug).length">
			<h2>more offers</h2>
			<div class="row gy-3">
				<div
					class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4"
					:class="{ 'd-block d-sm-none d-lg-block': i === 2 }"
					v-for="(offer, i) in offers
						.filter((e) => e.slug !== offerIndex.slug)
						.slice(0, 3)"
					:key="offer.id">
					<offers-card :offer="offer"></offers-card>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import OffersCard from '@/components/OffersCard.vue';
import Loader from '@/components/Loader.vue';
import FeedbackForm from '@/components/FeedbackForm.vue';
import { mapGetters } from 'vuex';
export default {
	name: 'ProductsView',
	components: { OffersCard, Loader, FeedbackForm },
	data() {
		return {
			offerIndex: {},

			openForm: false,

			loading: true,
			index: 0,
			visible: false,
		};
	},
	methods: {
		fetch() {
			this.loading = true;
			this.$store
				.dispatch('products/fetchAllOrGet', {
					slug: this.$route.params.slug,
				})
				.then((res) => {
					this.offerIndex = this.productsGetter(
						this.$route.params.slug,
					);
					this.loading = false;
				});
		},
		handleHide() {
			this.visible = false;
		},
	},
	watch: {
		'$route.params.slug'() {
			this.fetch();
			window.scrollTo('smoth', { x: 0, y: 0 });
		},
	},
	computed: {
		...mapGetters({
			productsGetter: 'products/getBySlug',
			offers: 'products/getFeatured',
		}),
	},
	mounted() {
		this.fetch();
	},
	metaInfo() {
		return {
			title: this.offerIndex.name,
			titleTemplate: '%s | R.T.A.S',
		};
	},
};
</script>

<style lang="scss" scoped>
@import '@/style/style.scss';
.offer-description {
	min-height: 200px;
}
%main-image {
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 0 5px #0001;
	aspect-ratio: 1 / 1;
}
h1,
h2 {
	text-transform: capitalize;
	color: $main-color;
}
h1 {
	font-size: 2rem;
	font-weight: bold;
}
h2 {
	font-size: 1.5rem;
	font-weight: bold;
}

/****************/
.main-image {
	@extend %main-image;
}
.more-images__item {
	@media (max-width: 992px) {
		width: calc(33.333333% - 20px);
	}
}
.more-images__item--overflow {
	position: relative;
	&::after {
		content: var(--overflow-images);
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		color: white;
		background-color: #0006;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-size: 1.5rem;
		font-weight: bold;
	}
}

.form-dialog {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: #0004;
	z-index: 100;
	.form-close {
		position: absolute;
		top: 20px;
		right: 20px;
	}
}
</style>
