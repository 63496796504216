<template>
	<!-- loader -->
	<loader v-if="loading"> </loader>

	<!-- page -->
	<div
		class="container page py-5"
		v-else>
		<div class="row gy-3">
			<div
				class="col-12 col-sm-6 col-md-4 col-lg-3"
				v-for="newsIndex in news"
				:key="newsIndex.id">
				<news-card :news="newsIndex"></news-card>
			</div>
		</div>
	</div>
</template>

<script>
import NewsCard from '@/components/NewsCard.vue';
import Loader from '@/components/Loader.vue';
import { mapState } from 'vuex';
export default {
	name: 'AllNews',
	components: {
		NewsCard,
		Loader,
	},
	data() {
		return {
			loading: true,
		};
	},
	computed: {
		...mapState({
			news: (state) => state.news.news,
		}),
	},
	methods: {
		fetch() {
			this.loading = true;
			this.$store.dispatch('news/fetchAllOrGet').then(() => {
				this.loading = false;
			});
		},
	},
	mounted() {
		this.fetch();
	},
	metaInfo() {
		return {
			title: 'News',
			titleTemplate: '%s | R.T.A.S',
		};
	},
};
</script>
