<template>
	<router-link :to="{ name: 'OffersView', params: { slug: offer.slug } }">
		<div class="offers-card">
			<div
				class="offers-card__content"
				:style="`background-image: url('${offer.primaryImage.url}');`">
				<div class="offers-card__text">
					<div
						class="offers-card__text-title white-text fw-bold mb-1 fs-5">
						{{ offer.name }}
					</div>
					<div class="offers-card__text-summary white-text fw-light">
						{{ offer.summary }}
					</div>
				</div>
				<div class="offers-card-overlayer"></div>
			</div>
			<div
				class="offers-card__sale"
				v-if="offer.code">
				<div class="offers-card__sale-top">UP TO</div>
				<div class="offers-card__sale-value">{{ offer.code }}</div>
				<div class="offers-card__sale-bottom">OFF</div>
			</div>
		</div>
	</router-link>
</template>

<script>
export default {
	name: 'OffersCard',
	props: ['offer'],
};
</script>
<style lang="scss" scoped>
@import '@/style/style.scss';
.offers-card {
	position: relative;
	aspect-ratio: 4 / 5;
	@supports not (aspect-ratio: 4 / 5) {
		height: 400px;
		@media (max-width: 992px) {
			height: 350px;
		}
		@media (max-width: 768px) {
			height: 330px;
		}
		@media (max-width: 576px) {
			height: 300px;
		}
	}
}
.offers-card__content {
	background-size: cover;
	display: flex;
	align-items: flex-end;
	height: 100%;
	padding: 20px;
	border-radius: 10px;

	position: relative;
	overflow: hidden;
	transition: 0.3s;
	&:hover {
		box-shadow: 0 0 15px #0005;
	}
}
.offers-card__text {
	position: relative;
	z-index: 2;
}
.offers-card-overlayer {
	background: $main-color-opacity-2;
	position: absolute;
	bottom: 0;
	right: 0;
	transform: rotate(18deg) translate(70px, 65%);
	height: 100%;
	width: 150%;
}
.offers-card__sale {
	position: absolute;
	top: -15px;
	left: -25px;
}
</style>
