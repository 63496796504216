var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('loader'):_c('div',{staticClass:"container page py-5"},[_c('vue-easy-lightbox',{attrs:{"moveDisabled":"","visible":_vm.visible,"imgs":_vm.newsIndex.images.map((e) => e.url),"index":_vm.index},on:{"hide":_vm.handleHide}}),_c('section',{staticClass:"main-content row mb-5"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-5"},[_c('div',{staticClass:"main-image image image--cover my-3"},[_c('img',{attrs:{"src":_vm.newsIndex.primaryImage.url,"alt":_vm.newsIndex.title}})])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-7"},[_c('h1',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.newsIndex.title))]),_c('p',{staticClass:"date mb-2 text-muted"},[_vm._v(" "+_vm._s(_vm.DateFormater(_vm.newsIndex.date))+" ")]),_c('div',{staticClass:"news-description",domProps:{"innerHTML":_vm._s(_vm.newsIndex.content)}}),(_vm.newsIndex.images.length)?_c('div',{staticClass:"more-images mb-5"},[_c('h2',[_vm._v("more images")]),_c('div',{staticClass:"more-images__contant"},_vm._l((_vm.newsIndex.images),function(image,i){return _c('div',{key:image.id,staticClass:"more-images__item image image--cover",class:{
							'more-images__item--overflow':
								i === 3 && _vm.newsIndex.length > 4,
						},staticStyle:{"--overflow-images":"'5+'"},on:{"click":function($event){_vm.index = i;
							_vm.visible = true;}}},[_c('img',{attrs:{"src":image.url,"alt":`${_vm.newsIndex.title}-image-${i}`}})])}),0)]):_vm._e()])]),(_vm.news.filter((e) => e.slug !== _vm.$route.params.slug).length)?_c('section',{staticClass:"more-news"},[_c('h2',[_vm._v("more news")]),_c('div',{staticClass:"row gy-3"},_vm._l((_vm.news
					.filter((e) => e.slug !== _vm.$route.params.slug)
					.slice(0, 3)),function(newsIndex,i){return _c('div',{key:newsIndex.id,staticClass:"col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4",class:{ 'd-block d-sm-none d-lg-block': i === 2 }},[_c('news-card',{attrs:{"news":newsIndex}})],1)}),0)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }