import * as types from "../mutations"

export default {
    namespaced: true,

    status: {
        categories: []
    },

    actions: {
        //fetch all categories
        fetchAll({ commit }) {
            return axios.get('/categories').then(res => {
                commit(types.CATEGORIES_STORE, res.data)
                return res.data
            })
        },
    },

    mutations: {
        //fill the state with categories
        [types.CATEGORIES_STORE](state, categories) {
            state.categories = categories
        }
    },

    getters: {
        //return one categories by it's slug
        getBySlug: state => slug => state.categories.find(e => e.slug === slug)
    }
}