export const NEWS_STORE = "NEWS_STORE"

export const ALBUMS_STORE = "ALBUMS_STORE"

export const PRODUCTS_STORE = "PRODUCTS_STORE"

export const CATEGORIES_STORE = "CATEGORIES_STORE"

export const SLIDER_STORE = "SLIDER_STORE"

export const ABOUT_STORE = "ABOUT_STORE"

export const CONTACTS_STORE = "CONTACTS_STORE"
