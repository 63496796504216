<template>
	<!-- loader -->
	<loader v-if="loading"> </loader>

	<!-- page -->
	<div
		class="container page py-5"
		v-else>
		<div class="row gy-3">
			<div
				class="col-12 col-sm-6 col-md-4 col-lg-3"
				v-for="productsIndex in products.filter((e) => e.isFeatured)"
				:key="productsIndex.id">
				<offers-card :offer="productsIndex"></offers-card>
			</div>
		</div>
	</div>
</template>

<script>
import OffersCard from '@/components/OffersCard.vue';
import Loader from '@/components/Loader.vue';
import { mapState } from 'vuex';
export default {
	name: 'AllProducts',
	components: {
		OffersCard,
		Loader,
	},
	data() {
		return {
			loading: true,
		};
	},
	computed: {
		...mapState({
			products: (state) => state.products.products,
		}),
	},
	methods: {
		fetch() {
			this.loading = true;
			this.$store.dispatch('products/fetchAll').then(() => {
				this.loading = false;
			});
		},
	},
	mounted() {
		this.fetch();
	},
	metaInfo() {
		return {
			title: 'Products',
			titleTemplate: '%s | R.T.A.S',
		};
	},
};
</script>
