<template>
	<div class="loader-container center-item">
		<div class="loader"></div>
	</div>
</template>

<script>
export default {
	name: 'Loader',
};
</script>

<style lang="scss">
@import '@/style/style.scss';
.loader-container {
	height: 50vh;
	margin-top: 200px;
}

.loader {
	width: 60px;
	aspect-ratio: 1;
	color: $main-color;
	background: linear-gradient(currentColor 0 0) 100% 0,
		linear-gradient(currentColor 0 0) 0 100%;
	background-size: 50.1% 50.1%;
	background-repeat: no-repeat;
	animation: f7-0 1s infinite steps(1);
}
.loader::before,
.loader::after {
	content: '';
	position: absolute;
	inset: 0 50% 50% 0;
	background: currentColor;
	transform: scale(var(--s, 1)) perspective(150px) rotateY(0deg);
	transform-origin: bottom right;
	animation: f7-1 0.5s infinite linear alternate;
}
.loader::after {
	--s: -1, -1;
}

@keyframes f7-0 {
	0% {
		transform: scaleX(1) rotate(0deg);
	}
	50% {
		transform: scaleX(-1) rotate(-90deg);
	}
}

@keyframes f7-1 {
	49.99% {
		transform: scale(var(--s, 1)) perspective(150px) rotateX(-90deg);
		filter: grayscale(0);
	}
	50% {
		transform: scale(var(--s, 1)) perspective(150px) rotateX(-90deg);
		filter: grayscale(0.4);
	}
	100% {
		transform: scale(var(--s, 1)) perspective(150px) rotateX(-180deg);
		filter: grayscale(0.4);
	}
}
</style>
