<template>
	<!-- loader -->
	<loader v-if="loading"> </loader>
	<div
		v-else
		class="contact-container">
		<div class="container page py-5">
			<div class="row gy-5 gx-4 justify-content-center">
				<div class="col-1 position-relative">
					<div class="image about-us-image about-us-image--1">
						<img
							src="/img/home-image-2.png"
							alt="" />
					</div>
				</div>
				<div class="col-10 text-center mb-4">
					<h1 class="text-uppercase fs-2 fw-bold main-text">
						about us
					</h1>
					<div v-html="about.value"></div>
				</div>
				<div class="col-1 position-relative">
					<div class="image about-us-image about-us-image--2">
						<img
							src="/img/home-image-1.png"
							alt="" />
					</div>
				</div>
				<div class="col-10 col-md-6 col-lg-6">
					<h2 class="main-text">Send us you Massage</h2>
					<feedback-form :inputClass="'contact-us-inputs'">
						<template v-slot:button="{ tabindex }">
							<button
								type="submit"
								class="button main-bg white-text"
								:tabindex="tabindex">
								Send
							</button>
						</template>
					</feedback-form>
				</div>
				<div class="col-10 col-md-4 col-lg-4">
					<h2 class="main-text">Contact Us</h2>
					<ul v-if="contacts.length">
						<li
							class="my-2 location"
							v-if="contacts[0].address">
							{{ contacts[0].address }}
						</li>
						<li
							class="my-2 phone"
							v-if="contacts[0].phone">
							<a
								:href="`tel://${contacts[0].phone}`"
								target="_blank">
								{{ contacts[0].phone }}
							</a>
						</li>
						<li
							class="my-2 email"
							v-if="contacts[0].email">
							<a
								:href="`mailto://${contacts[0].email}`"
								target="_blank">
								{{ contacts[0].email }}
							</a>
						</li>
						<li
							v-if="contacts.length"
							class="my-2">
							<div class="social">
								<a
									v-if="contacts[0].facebook"
									:href="contacts[0].facebook"
									class="facebook">
								</a>
								<a
									:href="contacts[0].instagram"
									v-if="contacts[0].instagram"
									class="instagram">
								</a>
								<a
									:href="contacts[0].linkedIn"
									v-if="contacts[0].linkedIn"
									class="linkedIn">
								</a>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FeedbackForm from '@/components/FeedbackForm.vue';
import Loader from '@/components/Loader.vue';
import { mapState, mapGetters } from 'vuex';
export default {
	name: 'ContactUs',
	data() {
		return {
			loading: true,
		};
	},
	components: {
		Loader,
		FeedbackForm,
	},
	computed: {
		...mapState({
			contacts: (state) => state.contacts.contacts,
		}),

		...mapGetters({
			about: 'aboutus/about',
		}),
	},
	mounted() {
		Promise.all([
			this.$store.dispatch('contacts/fetchAll'),
			this.$store.dispatch('aboutus/fetchAll'),
		]).then((res) => {
			this.loading = false;
		});
	},
	metaInfo() {
		return {
			title: 'Contact Us',
			titleTemplate: '%s | R.T.A.S',
		};
	},
};
</script>

<style lang="scss" scoped>
@import '@/style/style.scss';
a:hover {
	color: $sec-color;
}
.contact-container {
	overflow: hidden;
}
.contact-us-inputs {
	border: 1px solid #555;
	border-radius: 10px;
}
.about-us-image {
	position: absolute;
	top: -95px;
	width: 500px;
	z-index: -1;
	&--1 {
		right: -70px;
		@media (max-width: 576px) {
			right: -150px;
		}
	}
	&--2 {
		left: -70px;
		@media (max-width: 576px) {
			left: -150px;
			top: 100px;
		}
	}
}
</style>
