<template>
	<div
		class="white-text nav"
		:class="{ 'pages-nav': $route.name !== 'Home' }"
		ref="nav">
		<div class="top-nav black-bg grey-text w-100 py-2">
			<div
				class="container d-flex"
				v-if="contacts.length">
				<div
					class="top-nav__item location"
					v-if="contacts[0].address">
					{{ contacts[0].address }}
				</div>
				<div
					class="top-nav__item phone"
					v-if="contacts[0].phone">
					<a
						:href="`tel://${contacts[0].phone}`"
						target="_blank">
						{{ contacts[0].phone }}
					</a>
				</div>
				<div
					class="top-nav__item email"
					v-if="contacts[0].email">
					<a
						:href="`tel://${contacts[0].email}`"
						target="_blank">
						{{ contacts[0].email }}
					</a>
				</div>
			</div>
		</div>
		<div
			class="container py-4 d-flex justify-content-between align-items-center align-items-md-start">
			<div class="brand">
				<router-link :to="{ name: 'Home' }">
					<div class="image">
						<img
							src="/logo.png"
							alt="" />
					</div>
				</router-link>
			</div>
			<div
				class="nav-icon d-block d-md-none touch"
				@click="showNav = !showNav"
				ref="toggle"></div>
			<nav
				:class="{ show: showNav }"
				ref="drawer">
				<ul class="d-flex nav-list">
					<li class="nav-item">
						<router-link :to="{ name: 'Home' }">home</router-link>
					</li>
					<li class="nav-item">
						<router-link :to="{ name: 'AllOffers' }"
							>offers</router-link
						>
					</li>
					<li class="nav-item">
						<router-link :to="{ name: 'AllNews' }"
							>news</router-link
						>
					</li>
					<li class="nav-item">
						<router-link :to="{ name: 'ContactUs' }"
							>contact us</router-link
						>
					</li>
					<li
						class="nav-item search"
						v-if="false">
						<input
							type="text"
							placeholder="search..."
							class="search-input"
							:class="{ show: showSearch }" />
						<span
							@click="showSearch = !showSearch"
							class="touch search-toggle"
							:class="
								showSearch ? 'close-icon' : 'search-icon'
							"></span>
					</li>
				</ul>
			</nav>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MainNav',
	data() {
		return {
			loading: true,
			contacts: [],

			showSearch: false,
			showNav: false,
		};
	},
	methods: {
		navIsDown() {
			if (window.scrollY > 100) this.$refs.nav.classList.add('down');
			else this.$refs.nav.classList.remove('down');
		},
		closeNav(e) {
			if (
				this.$refs.drawer.contains(e.target) ||
				this.$refs.toggle.contains(e.target)
			)
				return;
			this.showNav = false;
		},
	},
	mounted() {
		this.$store.dispatch('contacts/fetchAll').then((res) => {
			this.contacts = res;
		});

		window.addEventListener('scroll', this.navIsDown);
		window.addEventListener('click', this.closeNav);
	},
};
</script>
<style lang="scss" scoped>
@import '@/style/style.scss';
.pages-nav {
	background-color: $main-color-opacity !important;
	.container {
		align-items: center !important;
	}
	.brand .image {
		height: 70px !important;
	}
}
.nav {
	position: fixed;
	right: 0;
	left: 0;
	top: 0;
	z-index: 5;
	transition: 0.3s;
}
.top-nav {
	.container {
		@media (max-width: 768px) {
			flex-wrap: wrap;
		}
	}
	&__item {
		font-size: 0.8rem;
		margin-right: 5rem;
		margin-bottom: 0.2rem;
		width: max-content;
		@media (max-width: 992px) {
			margin-right: 2rem;
		}
	}
}
.brand {
	.image {
		transition: 0.3s;
		height: 110px;
	}
}
.down {
	background: linear-gradient(to bottom, $main-color-opacity, transparent);
	.brand .image {
		height: 70px;
	}
}
.nav-item {
	margin-left: 4rem;
	text-transform: capitalize;
}
@media (min-width: 768px) {
	.search-icon {
		margin-left: 30px;
		transition: 0.2s;
	}
	.close-icon {
		margin-left: 5px;
		transition: 0.2s;
	}
}
.search-input {
	max-width: 0;
	transition: 0.3s;
	border: 1px solid white;
	background-color: #ddd;
	border-radius: 10px;
	padding: 0;
	&.show {
		padding: 0 10px;
		max-width: 200px;
	}
}

@media (max-width: 768px) {
	nav {
		position: absolute;
		right: 15px;
		left: 15px;
		top: -300%;
		z-index: 10;
		background: white;
		padding: 20px;
		border-radius: 5px;
		box-shadow: 0 0 10px #0003;
		transition: 0.5s;
		&.show {
			top: 200px;
		}
		.nav-list {
			flex-direction: column;
			align-items: center;
			color: black;
		}
		.nav-item {
			margin: initial;
			padding: 10px;
		}
	}
}
</style>
