var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('loader'):_c('div',{staticClass:"home"},[_c('header',[_c('div',{staticClass:"header-layer"},[_c('img',{attrs:{"src":"/shaps/overlayer2.png","alt":""}})]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"header-content"},[_c('hooper',{attrs:{"autoPlay":true,"wheelControl":false,"infiniteScroll":true}},_vm._l((_vm.slider),function(slid,i){return _c('slide',{key:i},[_c('p',{staticClass:"white-text slider-text"},[_vm._v(" "+_vm._s(slid.description)+" ")])])}),1),_c('router-link',{attrs:{"to":{ name: 'ContactUs' }}},[_c('div',{staticClass:"button button--liner text-uppercase white-text"},[_vm._v(" contact us ")])])],1)]),_c('div',{staticClass:"header-image image"},[_c('img',{attrs:{"src":"/img/home-image-1.png","alt":""}})])]),_c('main',{staticClass:"py-5"},[_c('section',{staticClass:"categorie-section container"},[_c('h2',{staticClass:"main-text fw-bold text-capitalize text-center mb-4"},[_vm._v(" our categories ")]),_c('div',{staticClass:"d-flex justify-content-center align-items-center flex-column flex-lg-row"},[_c('div',{staticClass:"mb-3 mb-lg-0",attrs:{"data-aos":"zoom-in"}},[_c('div',{staticClass:"categorie-card-container"},[_c('div',{staticClass:"categorie-card",style:(`background-image:url(${_vm.categories[0].image})`)},[_c('ul',{staticClass:"categorie-products center-item flex-column"},_vm._l((_vm.categories[0].categoriesProduct
										.slice(0, 8)
										.reverse()),function(product){return _c('li',{key:product.id,staticClass:"categorie-products__item first-categorie-item"},[_vm._v(" "+_vm._s(product.name)+" ")])}),0)]),_c('div',{staticClass:"categorie-title text-center fs-4 fw-bold text-capitalize mt-3"},[_vm._v(" "+_vm._s(_vm.categories[0].name)+" ")])])]),_c('div',{staticClass:"mb-3 mb-lg-0",attrs:{"data-aos":"zoom-in"}},[_c('div',{staticClass:"categorie-card-container"},[_c('div',{staticClass:"categorie-card",style:(`background-image:linear-gradient(#fff6,#fff6), url(${_vm.categories[1].image})`)},[_c('ul',{staticClass:"categorie-products center-item flex-column"},_vm._l((_vm.categories[1].categoriesProduct
										.slice(0, 8)
										.reverse()),function(product){return _c('li',{key:product.id,staticClass:"categorie-products__item first-categorie-item"},[_vm._v(" "+_vm._s(product.name)+" ")])}),0)]),_c('div',{staticClass:"categorie-title text-center fs-4 fw-bold text-capitalize mt-3"},[_vm._v(" "+_vm._s(_vm.categories[1].name)+" ")])])])])]),_c('section',{staticClass:"offers-section",class:{ 'no-offers': !_vm.offers.length }},[_c('div',{staticClass:"offers-back"},[_c('div',{staticClass:"offers-back__layout image"},[_c('img',{attrs:{"src":"/shaps/overlayer3.png","alt":""}})])]),_c('div',{staticClass:"offers-container"},[_c('div',{staticClass:"offers-content"},[(_vm.offers.length)?_c('h2',{staticClass:"text-capitalize white-text fw-bold mb-4"},[_vm._v(" special offers ")]):_vm._e(),_c('div',{staticClass:"offers-list"},_vm._l((_vm.offers.slice(0, 3)),function(offer){return _c('div',{key:offer.id,staticClass:"offers-item",attrs:{"data-aos":"fade-up"}},[_c('offers-card',{attrs:{"offer":offer}})],1)}),0)])])]),_c('section',{staticClass:"news-section container mb-4"},[_c('h2',{staticClass:"text-capitalize main-text fw-bold mb-4 text-center text-md-start"},[_vm._v(" latest news ")]),_c('div',{staticClass:"news-content"},[_c('div',{staticClass:"big-news-container",attrs:{"data-aos":"zoom-in"}},[_c('news-card',{attrs:{"news":_vm.arrNews[0]}})],1),_c('div',{staticClass:"more-news-container"},[_c('h3',{staticClass:"text-capitalize fw-bold fs-5"},[_vm._v("read more")]),_c('div',{staticClass:"more-news"},_vm._l((_vm.arrNews.slice(1, 4)),function(newsIndex){return _c('div',{key:newsIndex.id,staticClass:"more-news__item",attrs:{"data-aos":"fade-left"}},[_c('router-link',{attrs:{"to":{
									name: 'NewsView',
									params: { slug: newsIndex.slug },
								}}},[_c('div',{staticClass:"more-news__item-date text-muted"},[_vm._v(" "+_vm._s(_vm.DateFormater(newsIndex.date))+" ")]),_c('div',{staticClass:"more-news__item-title fw-bolds alt-text fs-5"},[_vm._v(" "+_vm._s(newsIndex.title)+" ")]),_c('div',{staticClass:"more-news__item-summary"},[_vm._v(" "+_vm._s(newsIndex.summary)+" ")])])],1)}),0),_c('router-link',{attrs:{"to":{ name: 'AllNews' }}},[_c('div',{staticClass:"button text-uppercase main-bg white-text"},[_vm._v(" see all news ")])])],1),_c('div',{staticClass:"image-container"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":"/img/home-image-2.png","alt":""}})])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }