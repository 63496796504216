import HomeView from "../views/HomeView.vue"
import AllOffers from "../views/Offers/AllOffers.vue"
import OffersView from "../views/Offers/OffersView.vue"
import AllNews from "../views/News/AllNews.vue"
import NewsView from "../views/News/NewsView.vue"
import AllAlbums from "../views/Albums/AllAlbums.vue"
import AlbumView from "../views/Albums/AlbumView.vue"
import ContactUs from "../views/ContactUs.vue"

export default [
    {
        path: '/',
        name: 'Home',
        component: HomeView
    },
    {
        path: '/all-offers',
        name: 'AllOffers',
        component: AllOffers
    },
    {
        path: '/offers/:slug',
        name: 'OffersView',
        component: OffersView
    },
    {
        path: '/all-news',
        name: 'AllNews',
        component: AllNews
    },
    {
        path: '/news/:slug',
        name: 'NewsView',
        component: NewsView
    },
    {
        path: '/all-albums',
        name: 'AllAlbums',
        component: AllAlbums
    },
    {
        path: '/album/:id',
        name: 'AlbumView',
        component: AlbumView
    },
    {
        path: '/contact-us',
        name: 'ContactUs',
        component: ContactUs
    },

]
