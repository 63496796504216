import * as types from "../mutations"

export default {
    namespaced: true,

    status: {
        contacts: []
    },

    actions: {
        //fetch all contacts
        fetchAll({ commit }) {
            return axios.get('/contacts').then(res => {
                commit(types.CONTACTS_STORE, res.data)
                return res.data
            })
        },
    },

    mutations: {
        //fill the state with contacts
        [types.CONTACTS_STORE](state, contacts) {
            state.contacts = contacts
        }
    },
}