<template>
	<!-- loader -->
	<loader v-if="loading"></loader>

	<div
		class="home"
		v-else>
		<header>
			<div class="header-layer">
				<img
					src="/shaps/overlayer2.png"
					alt="" />
			</div>
			<div class="container">
				<div class="header-content">
					<hooper
						:autoPlay="true"
						:wheelControl="false"
						:infiniteScroll="true">
						<slide
							v-for="(slid, i) in slider"
							:key="i">
							<p class="white-text slider-text">
								{{ slid.description }}
							</p>
						</slide>
					</hooper>
					<router-link :to="{ name: 'ContactUs' }">
						<div
							class="button button--liner text-uppercase white-text">
							contact us
						</div>
					</router-link>
				</div>
			</div>
			<div class="header-image image">
				<img
					src="/img/home-image-1.png"
					alt="" />
			</div>
		</header>
		<main class="py-5">
			<section class="categorie-section container">
				<h2 class="main-text fw-bold text-capitalize text-center mb-4">
					our categories
				</h2>
				<div
					class="d-flex justify-content-center align-items-center flex-column flex-lg-row">
					<div
						class="mb-3 mb-lg-0"
						data-aos="zoom-in">
						<div class="categorie-card-container">
							<div
								class="categorie-card"
								:style="`background-image:url(${categories[0].image})`">
								<ul
									class="categorie-products center-item flex-column">
									<li
										class="categorie-products__item first-categorie-item"
										v-for="product in categories[0].categoriesProduct
											.slice(0, 8)
											.reverse()"
										:key="product.id">
										{{ product.name }}
									</li>
								</ul>
							</div>
							<div
								class="categorie-title text-center fs-4 fw-bold text-capitalize mt-3">
								{{ categories[0].name }}
							</div>
						</div>
					</div>

					<div
						class="mb-3 mb-lg-0"
						data-aos="zoom-in">
						<div class="categorie-card-container">
							<div
								class="categorie-card"
								:style="`background-image:linear-gradient(#fff6,#fff6), url(${categories[1].image})`">
								<ul
									class="categorie-products center-item flex-column">
									<li
										class="categorie-products__item first-categorie-item"
										v-for="product in categories[1].categoriesProduct
											.slice(0, 8)
											.reverse()"
										:key="product.id">
										{{ product.name }}
									</li>
								</ul>
							</div>
							<div
								class="categorie-title text-center fs-4 fw-bold text-capitalize mt-3">
								{{ categories[1].name }}
							</div>
						</div>
					</div>
				</div>
			</section>
			<section
				class="offers-section"
				:class="{ 'no-offers': !offers.length }">
				<div class="offers-back">
					<div class="offers-back__layout image">
						<img
							src="/shaps/overlayer3.png"
							alt="" />
					</div>
				</div>
				<div class="offers-container">
					<div class="offers-content">
						<h2
							class="text-capitalize white-text fw-bold mb-4"
							v-if="offers.length">
							special offers
						</h2>
						<div class="offers-list">
							<div
								class="offers-item"
								v-for="offer in offers.slice(0, 3)"
								:key="offer.id"
								data-aos="fade-up">
								<offers-card :offer="offer"></offers-card>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="news-section container mb-4">
				<h2
					class="text-capitalize main-text fw-bold mb-4 text-center text-md-start">
					latest news
				</h2>
				<div class="news-content">
					<div
						class="big-news-container"
						data-aos="zoom-in">
						<news-card :news="arrNews[0]"></news-card>
					</div>
					<div class="more-news-container">
						<h3 class="text-capitalize fw-bold fs-5">read more</h3>
						<div class="more-news">
							<div
								class="more-news__item"
								v-for="newsIndex in arrNews.slice(1, 4)"
								:key="newsIndex.id"
								data-aos="fade-left">
								<router-link
									:to="{
										name: 'NewsView',
										params: { slug: newsIndex.slug },
									}">
									<div
										class="more-news__item-date text-muted">
										{{ DateFormater(newsIndex.date) }}
									</div>
									<div
										class="more-news__item-title fw-bolds alt-text fs-5">
										{{ newsIndex.title }}
									</div>
									<div class="more-news__item-summary">
										{{ newsIndex.summary }}
									</div>
								</router-link>
							</div>
						</div>
						<router-link :to="{ name: 'AllNews' }">
							<div
								class="button text-uppercase main-bg white-text">
								see all news
							</div>
						</router-link>
					</div>
					<div class="image-container">
						<div class="image">
							<img
								src="/img/home-image-2.png"
								alt="" />
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import NewsCard from '@/components/NewsCard.vue';
import OffersCard from '@/components/OffersCard.vue';
import Loader from '@/components/Loader.vue';
import { mapState, mapGetters } from 'vuex';
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import { DateFormater } from '@/helpers/helpers';

export default {
	name: 'HomeView',
	components: { NewsCard, OffersCard, Loader, Hooper, Slide },
	data() {
		return {
			loading: true,

			interval: null,
			firstCategories: [],
			firstCategoriesIndex: 0,
		};
	},
	computed: {
		arrNews() {
			return [
				...this.news.filter((e) => e.isFeatured),
				...this.news.filter((e) => !e.isFeatured),
			];
		},
		...mapState({
			news: (state) => state.news.news,
			categories: (state) => state.categories.categories,
			products: (state) => state.products.products,
			slider: (state) => state.slider.slider,
		}),
		...mapGetters({
			offers: 'products/getFeatured',
		}),
	},
	methods: {
		DateFormater,
		categoriesAnimation() {
			this.firstCategories.forEach((e) => e.classList.remove('big'));

			this.firstCategories[this.firstCategoriesIndex].classList.add(
				'big',
			);

			this.firstCategoriesIndex =
				(this.firstCategoriesIndex + 1) % this.firstCategories.length;
		},
	},
	mounted() {
		Promise.all([
			this.$store.dispatch('news/fetchAll'),
			this.$store.dispatch('products/fetchAll'),
			this.$store.dispatch('categories/fetchAll'),
			this.$store.dispatch('slider/fetchAll'),
		])
			.then(() => {
				this.loading = false;
			})
			.then(() => {
				this.firstCategories = document.querySelectorAll(
					'.first-categorie-item',
				);
				this.secCategories = document.querySelectorAll(
					'.sec-categorie-item',
				);

				this.interval = setInterval(this.categoriesAnimation, 1000);
			});
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	metaInfo() {
		return {
			titleTemplate: 'R.T.A.S',
		};
	},
};
</script>

<style lang="scss" scoped>
@import '@/style/style.scss';
.categorie-products__item {
	transition: 0.9s;
}
.categorie-products__item.big {
	transition: 0.3s;
	transform: scale(1.1);
	color: $main-color;
}
.slider-text {
	font-size: 1.2rem;
}
main {
	overflow-x: hidden;
	margin-top: -150px;
	position: relative;
	@media (max-width: 992px) {
		margin-top: -100px;
	}
	@media (max-width: 768px) {
		margin-top: -50px;
	}
}
header {
	background-image: linear-gradient(to right, #8576bede, #24145be3 70%),
		url('/public/img/header-bg.jpg');
	background-size: cover;
	position: relative;
	padding: 250px 0 200px;
	@media (min-width: 2000px) {
		height: 40vw;
	}
	.header-layer {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 100%;
		img {
			width: 100%;
			object-fit: contain;
		}
	}
	.header-content {
		width: 50%;
		@media (max-width: 768px) {
			width: 100%;
		}
		.button {
			position: relative;
			z-index: 2;
		}
	}
	.header-image {
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 50%;
		max-width: 850px;
		@media (max-width: 768px) {
			width: 70%;
			bottom: -80px;
		}
		@media (max-width: 576px) {
			width: 100%;
			bottom: -60px;
			left: 40%;
		}
	}
}
.categorie-card {
	width: 35vw;
	max-width: 500px;
	@media (max-width: 992px) {
		width: 40vw;
	}
	@media (max-width: 768px) {
		width: 90vw;
		max-width: 350px;
	}
	height: 350px;
	margin: 0 20px 20px;
	padding: 30px;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: $alt-color;
	border-radius: 15px;

	position: relative;
	overflow: hidden;
	.categorie-products {
		position: absolute;
		align-items: flex-start !important;
		top: 0;
		right: 25%;
		left: 20px;
		bottom: 0;
		&__item {
			margin: 7px 0;
		}
	}
	position: relative;
	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 150%;
		background-color: #786db1;
		transform: rotate(-8deg) translate(-223px, -122px);
		@media (max-width: 1200px) {
			transform: rotate(-8deg) translate(-160px, -122px);
		}
		@media (max-width: 992px) {
			background-color: #786db1aa;
			transform: rotate(-8deg) translate(-100px, -122px);
		}
		@media (max-width: 768px) {
			transform: rotate(-8deg) translate(-123px, -122px);
		}
	}
}
.offers-section {
	margin-top: -70px;
	margin-bottom: -130px;
	position: relative;
	@media (max-width: 768px) {
		margin-bottom: -70px;
	}
	@media (max-width: 576px) {
		margin-top: -30px;
	}
}
.offers-back {
	background-image: linear-gradient($main-color-opacity, $main-color-opacity),
		url('/public/img/offers-bg.jpg');
	background-size: 60%;
	background-position: 100% -100px;
	@media (max-width: 1200px) {
		background-size: 70%;
		background-position: 100% -50px;
	}
	@media (max-width: 992px) {
		background-size: 100%;
		background-position: 100% -50px;
	}

	position: absolute;
	right: 0;
	left: 0;
	top: 0;
	height: 50%;
	z-index: -1;
	.offers-back__layout {
		position: absolute;
		right: 0;
		left: 0;
		top: 0;
	}
}
.offers-container {
	background-image: url('/public/shaps/overlayer1.png');
	background-size: 100% 100%;
	padding: 170px 0 300px;
	@media (max-width: 768px) {
		background-image: url('/public/shaps/overlayer1-mobile.png');
	}
}

.offers-content {
	max-width: 1100px;
	padding: 0 25px;
	margin: auto;
}
.offers-list {
	display: flex;
	justify-content: space-between;
	@media (max-width: 768px) {
		flex-direction: column;
		align-items: center;
	}
	.offers-item {
		@media (min-width: 768px) {
			width: 30%;
			&:first-child {
				margin-top: 0 !important;
				margin-bottom: 40px;
			}
			&:nth-child(2) {
				margin-top: 100px;
			}
			&:nth-child(3),
			&:last-child {
				margin-top: 200px;
			}
		}
		@media (max-width: 768px) {
			width: 90vw;
			max-width: 350px;
			margin-bottom: 15px;
		}
	}
}
.no-offers {
	.offers-back {
		height: 73%;
		left: 50%;
		background-size: cover;
		@media (max-width: 992px) {
			left: 40%;
		}
		@media (max-width: 768px) {
			height: 70%;
			left: 30%;
		}
	}
	.offers-container {
		background-image: url('/public/shaps/overlayer1-no-data.png');
	}
}
.news-content {
	display: flex;
	@media (max-width: 768px) {
		flex-wrap: wrap;
		justify-content: center;
	}
	.big-news-container,
	.more-news-container,
	.image-container {
		padding: 0 15px;
		@media (max-width: 768px) {
			padding: 0;
		}
	}
	.big-news-container {
		width: 400px;
		padding-left: 0;
		@media (max-width: 768px) {
			width: 80%;
			margin-bottom: 15px;
			padding: 0;
		}
	}
	.more-news-container {
		position: relative;
		z-index: 2;
		width: 450px;
		@media (max-width: 768px) {
			max-width: 350px;
		}
	}
	.image-container {
		position: relative;
		.image {
			position: absolute;
			width: 57vw;
			max-width: 850px;
			bottom: -10%;
			left: -120px;
			@media (max-width: 768px) {
				width: 500px;
			}
		}
	}
}
.more-news {
	&__item {
		padding: 2rem 0;
		&-date {
			font-size: 0.8rem;
		}
		&-summary {
			line-height: 1.5rem;
			max-height: 3rem;
			overflow: hidden;
		}
	}
	&__item:not(:last-child) {
		border-bottom: 1px solid #333;
	}
}
</style>
