import * as types from "../mutations"

export default {
    namespaced: true,

    status: {
        slider: []
    },

    actions: {
        //fetch all slider
        fetchAll({ commit }) {
            return axios.get('/sliders').then(res => {
                commit(types.SLIDER_STORE, res.data)
                return res.data
            })
        },
    },

    mutations: {
        //fill the state with slider
        [types.SLIDER_STORE](state, slider) {
            state.slider = slider
        }
    },
}