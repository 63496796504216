<template>
	<div>
		<form
			@submit.prevent="submit"
			ref="form"
			class="feedback-form"
			id="feedback-form"
			method="post"
			novalidate="false">
			<!-- name input  -->
			<div
				class="input-container"
				:class="inputClass">
				<input
					v-model="name"
					type="text"
					ref="name"
					:class="{ invalid: !nameIsValid }"
					:placeholder="placeHolder?.name"
					required
					tabindex="0"
					form="feedback-form" />
			</div>

			<!-- email input -->
			<div
				class="input-container"
				:class="inputClass">
				<input
					v-model="email"
					type="email"
					ref="email"
					:class="{ invalid: !emailIsValid || !emailIsValidPatern }"
					:placeholder="placeHolder?.email"
					required
					tabindex="0"
					form="feedback-form" />
			</div>

			<!-- phone input -->
			<div
				class="input-container"
				:class="inputClass">
				<input
					v-model="phone"
					type="tel"
					ref="phone"
					:class="{ invalid: !phoneIsValid }"
					:placeholder="placeHolder?.phone"
					required
					form="feedback-form"
					tabindex="0" />
			</div>

			<!-- massage type select -->
			<div
				ref="DropdownToggler"
				class="input-container input-container--select"
				:class="inputClass"
				@click="openDropdown = !openDropdown"
				@keydown.space="openDropdown = !openDropdown"
				tabindex="0">
				<!-- massage type placeholder -->
				<span
					:class="{
						'muted-select-label': !type,
						'select-label--opend': openDropdown,
						invalid: !bodyIsValid,
					}"
					class="select-label">
					{{ type || placeHolder?.type }}
				</span>

				<!-- massage type dropdown -->
				<div
					class="type-dropdown"
					v-if="openDropdown">
					<ul>
						<!-- massage type dropdown item -->
						<li
							v-for="(typeIndex, i) in massageTypes"
							@click="type = typeIndex"
							@keydown.space="type = typeIndex"
							:key="`massage-type-${i}`"
							tabindex="0">
							{{ typeIndex }}
						</li>
					</ul>
				</div>
			</div>

			<!-- massage body -->
			<div
				class="input-container input-container--text-area"
				:class="inputClass">
				<textarea
					v-model="body"
					ref="body"
					:class="{ invalid: !bodyIsValid }"
					:placeholder="placeHolder?.body"
					required
					rows="5"
					form="feedback-form"
					tabindex="0"></textarea>
			</div>

			<!-- response massage -->
			<div
				v-if="showResultMassage"
				class="response-massage"
				:class="{ success: successfulSent }">
				{{ responseMassage }}
			</div>

			<!-- button slot (expected) -->
			<!-- 
                display: for dispaly the button
                success: become true if the respons.status = 200
                responseMassage: return response massage
                tabindex: expected value for tabindex
             -->
			<slot
				name="button"
				:disable="loading"
				:success="successfulSent"
				:responseMassage="responseMassage"
				:tabindex="0"></slot>
		</form>
	</div>
</template>

<script>
/**
 * props:
 * inputClass = name of css class will add to inputs
 * placeHolderFromi18n = if true get the placeholders from i18n
 * showResultMassage = if true show response massage in this component
 *
 * emits:
 * responseMassage: return {massage: the response massage, code: the response code (a.k.a response.status)}
 */
export default {
	name: 'FeedbackForm',

	props: {
		inputClass: {
			type: String,
			default: '',
		},
		placeHolderFromi18n: {
			type: Boolean,
			default: false,
		},
		showResultMassage: {
			type: Boolean,
			default: true,
		},
		massageBody: {
			type: String,
			default: null,
		},
		massageType: {
			type: String,
			default: null,
		},
	},

	data() {
		return {
			name: null,
			email: null,
			phone: null,
			type: this.massageType,
			body: this.massageBody,

			nameIsValid: true,
			emailIsValid: true,
			emailIsValidPatern: true,
			phoneIsValid: true,
			typeIsValid: true,
			bodyIsValid: true,

			placeHolder: {},
			massageTypes: [],

			openDropdown: false,

			loading: false,
			responseMassage: null,
			successfulSent: false,
		};
	},

	computed: {
		isEnglish() {
			return document.querySelector('html').lang === 'en';
		},
	},

	methods: {
		restartValidToTrue() {
			this.nameIsValid = true;
			this.emailIsValid = true;
			this.emailIsValidPatern = true;
			this.phoneIsValid = true;
			this.typeIsValid = true;
			this.bodyIsValid = true;
		},
		clearAll() {
			this.name = null;
			this.email = null;
			this.phone = null;
			this.type = null;
			this.body = null;
		},
		submit(event) {
			this.restartValidToTrue();
			// check if all inputs get a value
			if (!this.name) this.nameIsValid = false;
			if (!this.email) this.emailIsValid = false;
			if (!this.phone) this.phoneIsValid = false;
			if (!this.type) this.typeIsValid = false;
			if (!this.body) this.bodyIsValid = false;

			// check if the email input is email
			if (this.$refs.email.validity.typeMismatch)
				this.emailIsValidPatern = false;
			if (!this.emailIsValidPatern) {
				this.$refs.email.placeholder = this.isEnglish
					? 'this must be an email'
					: 'يرجى ادخال بريد إلكتروني صحيح';
				this.email = null;
			}

			if (
				!this.nameIsValid ||
				!this.emailIsValid ||
				!this.emailIsValidPatern ||
				!this.phoneIsValid ||
				!this.typeIsValid ||
				!this.typeIsValid
			)
				return;

			// start the post
			this.loading = true;
			this.successfulSent = false;
			this.$store
				.dispatch('feedback/feedback', {
					name: this.name,
					email: this.email,
					phone: this.phone,
					type: this.type,
					body: this.body,
				})
				.then((res) => {
					this.loading = false;

					// response massage
					if (res.status !== 200) this.successfulSent = false;
					switch (res.status) {
						case 200:
							this.responseMassage = this.isEnglish
								? 'Thank you, your massage had been sent.'
								: 'شكراً لك، قد تم إرسال رسالتك.';
							break;
						case 422:
							this.responseMassage = this.isEnglish
								? 'Some filed may be missing!'
								: 'لم يتم تعبئة كل الحقول!';
							break;
						case 500:
							this.responseMassage = this.isEnglish
								? 'Something went wront!'
								: 'حدث خطأ ما!';
							break;

						default:
							break;
					}

					// emit the response massage
					this.$emit('responseMassage', {
						massage: this.responseMassage,
						code: res.status,
					});
				});
		},
	},

	created() {
		const placeHolderEn = {
				name: 'Name',
				email: 'Email',
				phone: 'Phone',
				type: 'Massage type',
				body: 'Your Massage',
			},
			placeHolderAr = {
				name: 'الأسم',
				email: 'البريد الإلكتروني',
				phone: 'رقم الهاتف',
				type: 'نوع الرسالة',
				body: 'الرسالة',
			},
			massageTypesEn = [
				'Suggestion',
				'Enquiry',
				'Complaint',
				'request a quote',
			],
			massageTypesAr = ['اقتراح', 'استفسار', 'شكوى', 'طلب نسخة'];

		//chose the placeholders language
		this.placeHolder = this.isEnglish ? placeHolderEn : placeHolderAr;
		// change the placeholders to i18n
		if (this.placeHolderFromi18n && typeof this.$t === 'function') {
			this.placeHolder = {
				name: this.$t('contact-us.form.name'),
				email: this.$t('contact-us.form.email'),
				phone: this.$t('contact-us.form.phone'),
				type: this.$t('contact-us.form.type'),
				body: this.$t('contact-us.form.body'),
			};
		}

		//chose the massage type language
		this.massageTypes = this.isEnglish ? massageTypesEn : massageTypesAr;

		//close dropdown in window
		window.addEventListener('click', (e) => {
			if (this.$refs.DropdownToggler?.contains(e.target)) return;
			this.openDropdown = false;
		});
	},
};
</script>

<style scoped lang="scss">
$feedback-breackpoint: 768px;
$input-padding: 0.3em 1em;
$input-color: black;
$input-opacity: 0.5;

$dropdown-border-radius: 10px;
$dropdown-background: white;
$dropdown-padding: 10px;
$dropdown-items-padding: 4px 0;

$response-massage-font-size: 0.8em;
$response-massage-opacity: 0.8;
%response-massage-success {
	font-weight: bold;
}

$invalid-color: rgb(255, 64, 43);
%invalid-aniamtion {
	animation: invalidAnimation 0.3s;
	@keyframes invalidAnimation {
		0% {
			padding-inline-start: 0;
		}
		25% {
			padding-inline-start: 5px;
		}
		50% {
			padding-inline-start: 0;
		}
		75% {
			padding-inline-start: 5px;
		}
		100% {
			padding-inline-start: 0;
		}
	}
}

/****************/
.feedback-form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
	@media (max-width: $feedback-breackpoint) {
		grid-template-columns: 1fr;
	}
}

.input-container {
	padding: $input-padding;

	&--select {
		position: relative;
		.type-dropdown {
			position: absolute;
			right: 0;
			left: 0;
			top: 110%;
			background: $dropdown-background;
			box-shadow: 0 0 20px #0003;
			border-radius: $dropdown-border-radius;
			padding: $dropdown-padding;
			animation: typeDropdownInter 0.5s forwards;
			@keyframes typeDropdownInter {
				from {
					transform: translateY(-20px);
					opacity: 0;
				}
				to {
					transform: translateY(0);
					opacity: 1;
				}
			}

			> ul {
				list-style: none;

				> li {
					padding: $dropdown-items-padding;
				}
			}
		}
	}

	&--text-area {
		grid-column-start: 1;
		grid-column-end: 3;
		@media (max-width: $feedback-breackpoint) {
			grid-column-start: initial;
			grid-column-end: initial;
		}
	}
}

.response-massage {
	font-size: $response-massage-font-size;
	opacity: $response-massage-opacity;
	text-decoration: underline;
	grid-column-start: 1;
	grid-column-end: 3;
	@media (max-width: $feedback-breackpoint) {
		grid-column-start: initial;
		grid-column-end: initial;
	}
	&.success {
		@extend %response-massage-success;
	}
}

.invalid::placeholder,
.invalid.muted-select-label {
	color: $invalid-color;
}
.invalid {
	@extend %invalid-aniamtion;
}

//select arrow
.select-label::after {
	content: '';
	display: block;
	position: absolute;
	right: 20px;
	top: 50%;
	width: 5px;
	height: 5px;
	border: 3px solid $input-color;
	border-right: none;
	border-top: none;

	transform: rotate(-45deg);
	transition: 0.1s;
}
.select-label--opend::after {
	transform: rotate(135deg);
}

.muted-select-label,
input::placeholder,
textarea::placeholder {
	opacity: $input-opacity;
	color: $input-color;
}
textarea,
input {
	border: none;
	outline: none;
	box-shadow: none;
	width: 100%;
	background: transparent;
	resize: none;
}
</style>
