import Vue from 'vue'
import Vuex from 'vuex'
import feedback from './modules/feedback'
import news from './modules/news'
import albums from './modules/albums'
import products from './modules/products'
import categories from './modules/categories'
import slider from './modules/slider'
import aboutus from './modules/aboutus'
import contacts from './modules/contacts'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    news,
    albums,
    feedback,
    products,
    categories,
    slider,
    aboutus,
    contacts
  }
})
