<template>
	<div id="app">
		<main-nav></main-nav>
		<router-view />
		<main-footer />
	</div>
</template>
<script>
import MainFooter from './components/MainFooter.vue';
import MainNav from './components/MainNav.vue';
import AOS from 'aos';
export default {
	components: { MainFooter, MainNav },
	setup() {
		AOS.init({
			startEvent: 'load',
			once: true,
		});
	},
};
</script>
<style lang="scss">
@import '@/style/helpers.scss';
@import '@/style/components.scss';
@import '@/style/style.scss';

@font-face {
	font-family: MainFont;
	src: url('/public/Kanit/Kanit-Regular.ttf');
	font-weight: 400;
}
@font-face {
	font-family: MainFont;
	src: url('/public/Kanit/Kanit-Bold.ttf');
	font-weight: 700 900;
}
@font-face {
	font-family: MainFont;
	src: url('/public/Kanit/Kanit-Medium.ttf');
	font-weight: 500 600;
}
@font-face {
	font-family: MainFont;
	src: url('/public/Kanit/Kanit-Medium.ttf');
	font-weight: bold;
}
@font-face {
	font-family: MainFont;
	src: url('/public/Kanit/Kanit-Light.ttf');
	font-weight: 100 300;
}
* {
	font-family: MainFont;
}
body {
	background: $bg-color;
}
.page {
	margin-top: 200px !important;
}
.button {
	width: max-content;
	cursor: pointer;
	padding: 0.5em 2em;
	border-radius: 10px;
	transition: 0.2s;
	&:hover {
		box-shadow: 0 0 10px #0006;
	}
	&--liner {
		border: 1px solid white;
		&:hover {
			background: white;
			color: black !important;
		}
	}
}
.offers-card__sale {
	display: flex;
	color: white;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url('/public/shaps/discount.png');
	background-size: contain;
	width: 110px;
	height: 110px;
	@media (max-width: 992px) {
		width: 80px;
		height: 80px;
	}
	&-value {
		font-size: 2rem;
		line-height: 2rem;
		font-weight: 900;
		@media (max-width: 992px) {
			font-size: 1.2rem;
			line-height: 1.2rem;
		}
	}
	&-top {
		font-size: 0.9rem;
		line-height: 0.6rem;
		@media (max-width: 992px) {
			font-size: 0.8rem;
			line-height: 0.7rem;
		}
	}
	&-bottom {
		font-size: 0.9rem;
		line-height: 1rem;
		@media (max-width: 992px) {
			font-size: 0.8rem;
			line-height: 0.8rem;
		}
	}
	&-value::after {
		content: '%';
	}
}
</style>
