import * as types from "../mutations"

export default {
    namespaced: true,

    status: {
        products: []
    },

    actions: {
        //fetch all products
        fetchAll({ commit }) {
            return axios.get('/products').then(res => {
                commit(types.PRODUCTS_STORE, res.data)
                return res.data
            })
        },

        //return the products. and if it's empty fetch products
        fetchAllOrGet({ commit, state }) {
            return state.products || axios.get('/products').then(res => {
                commit(types.PRODUCTS_STORE, res.data)
                return res.data
            })
        },

        //fetch one products by it's slug
        fetchBySlug({ commit }, { slug }) {
            return axios.get(`products/${slug}`).then(res => res.data)
        },

        //get one products by it's slug, and if it's not found fetch it
        fetchByStateOrSlug({ commit, state }, { slug }) {
            let products = null;
            if (state.products) {
                products = state.products.find(e => e.slug === slug)
            }
            return products || axios.get(`/products/${slug}`).then(res => res.data)
        }
    },

    mutations: {
        //fill the state with products
        [types.PRODUCTS_STORE](state, products) {
            state.products = products
        }
    },

    getters: {
        //return one products by it's slug
        getBySlug: state => slug => state.products.find(e => e.slug === slug),
        getFeatured: state => state.products.filter(e => e.isFeatured)
    }
}