<template>
	<!-- loader -->
	<loader v-if="loading"></loader>

	<!-- page -->
	<div
		class="container page py-5"
		v-else>
		<!-- light box -->
		<vue-easy-lightbox
			moveDisabled
			:visible="visible"
			:imgs="newsIndex.images.map((e) => e.url)"
			:index="index"
			@hide="handleHide"></vue-easy-lightbox>

		<!-- main section -->
		<section class="main-content row mb-5">
			<!-- image -->
			<div class="col-12 col-md-6 col-lg-5">
				<div class="main-image image image--cover my-3">
					<img
						:src="newsIndex.primaryImage.url"
						:alt="newsIndex.title" />
				</div>
			</div>

			<!-- descritption -->
			<div class="col-12 col-md-6 col-lg-7">
				<h1 class="mb-0">{{ newsIndex.title }}</h1>
				<p class="date mb-2 text-muted">
					{{ DateFormater(newsIndex.date) }}
				</p>
				<div
					class="news-description"
					v-html="newsIndex.content"></div>
				<!-- more images -->
				<div
					class="more-images mb-5"
					v-if="newsIndex.images.length">
					<h2>more images</h2>
					<div class="more-images__contant">
						<div
							class="more-images__item image image--cover"
							:class="{
								'more-images__item--overflow':
									i === 3 && newsIndex.length > 4,
							}"
							style="--overflow-images: '5+'"
							v-for="(image, i) in newsIndex.images"
							:key="image.id"
							@click="
								index = i;
								visible = true;
							">
							<img
								:src="image.url"
								:alt="`${newsIndex.title}-image-${i}`" />
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- more news -->
		<section
			class="more-news"
			v-if="news.filter((e) => e.slug !== $route.params.slug).length">
			<h2>more news</h2>
			<div class="row gy-3">
				<div
					class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4"
					:class="{ 'd-block d-sm-none d-lg-block': i === 2 }"
					v-for="(newsIndex, i) in news
						.filter((e) => e.slug !== $route.params.slug)
						.slice(0, 3)"
					:key="newsIndex.id">
					<news-card :news="newsIndex"></news-card>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import NewsCard from '@/components/NewsCard.vue';
import Loader from '@/components/Loader.vue';
import { DateFormater } from '@/helpers/helpers';
import { mapState, mapGetters } from 'vuex';
export default {
	name: 'NewsView',
	components: { NewsCard, Loader },
	data() {
		return {
			newsIndex: {},

			loading: true,
			index: 0,
			visible: false,
		};
	},
	watch: {
		'$route.params'() {
			this.newsIndex = this.newsGetter(this.$route.params.slug);
		},
	},
	methods: {
		fetch() {
			this.loading = true;
			this.$store
				.dispatch('news/fetchAllOrGet', {
					slug: this.$route.params.slug,
				})
				.then((res) => {
					this.newsIndex = this.newsGetter(this.$route.params.slug);
					this.loading = false;
				});
		},
		DateFormater,
		handleHide() {
			this.visible = false;
		},
	},
	computed: {
		...mapGetters({
			newsGetter: 'news/getBySlug',
		}),
		...mapState({
			news: (state) => state.news.news,
		}),
	},
	mounted() {
		this.fetch();
	},
	metaInfo() {
		return {
			title: this.newsIndex.title,
			titleTemplate: '%s | News | R.T.A.S',
		};
	},
};
</script>

<style lang="scss" scoped>
@import '@/style/style.scss';
%main-image {
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 0 5px #0001;
	aspect-ratio: 1 / 1;
}
h1,
h2 {
	text-transform: capitalize;
	color: $main-color;
}
h1 {
	font-size: 2rem;
	font-weight: bold;
}
h2 {
	font-size: 1.5rem;
	font-weight: bold;
}

/****************/
.main-image {
	@extend %main-image;
}
.more-images__item {
	@media (max-width: 992px) {
		width: calc(33.333333% - 20px);
	}
}
.more-images__item--overflow {
	position: relative;
	&::after {
		content: var(--overflow-images);
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		color: white;
		background-color: #0006;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-size: 1.5rem;
		font-weight: bold;
	}
}
</style>
<style>
.news-description a {
	text-decoration: underline !important;
}
</style>
