<template>
	<div class="grey-text black-bg">
		<div class="top-section">
			<div class="container pt-5 pb-4">
				<div class="page-footer">
					<!-- logo and description -->
					<div class="page-footer__part page-footer__part--main">
						<div class="d-flex flex-column flex-sm-row">
							<div class="brand-image image">
								<img
									src="/logo.png"
									alt="" />
							</div>
							<div>
								<div
									class="about"
									v-html="aboutValue"></div>
							</div>
						</div>
					</div>
					<div
						class="page-footer__part page-footer__part--spacer-1"></div>

					<!-- website links -->
					<div
						class="page-footer__part page-footer__part--page-links">
						<div class="links links--page-links">
							<div class="links-title">links</div>
							<ul class="links-list">
								<li class="links-list__item">
									<router-link :to="{ name: 'Home' }"
										>home</router-link
									>
								</li>
								<li class="links-list__item">
									<router-link :to="{ name: 'AllNews' }"
										>news</router-link
									>
								</li>
								<li class="links-list__item">
									<router-link :to="{ name: 'AllOffers' }"
										>offers</router-link
									>
								</li>
								<li class="links-list__item">
									<router-link :to="{ name: 'ContactUs' }"
										>contact</router-link
									>
								</li>
							</ul>
						</div>
					</div>
					<div
						class="page-footer__part page-footer__part--spacer-2"></div>

					<!-- categories links -->
					<!-- <div class="page-footer__part page-footer__part--categories">
					<div class="links links--categories">
						<div class="links-title">categories</div>
						<ul class="links-list">
							<li class="links-list__item">categories 1</li>
							<li class="links-list__item">categories 2</li>
							<li class="links-list__item">categories 3</li>
							<li class="links-list__item">categories 4</li>
							<li class="links-list__item">categories 5</li>
							<li class="links-list__item">categories 6</li>
						</ul>
					</div>
				</div>
				<div
					class="page-footer__part page-footer__part--spacer-3"></div> -->

					<!-- contact us -->
					<div
						class="page-footer__part page-footer__part--contact-us">
						<div class="links links--contact-us">
							<div class="links-title">contact us</div>
							<ul
								class="links-list"
								v-if="contacts.length">
								<li
									class="links-list__item"
									v-if="contacts[0].address">
									{{ contacts[0].address }}
								</li>
								<li
									class="links-list__item"
									v-if="contacts[0].phone">
									<a
										:href="`tel://${contacts[0].phone}`"
										target="_blank">
										{{ contacts[0].phone }}
									</a>
								</li>
								<li
									class="links-list__item"
									v-if="contacts[0].email">
									<a
										:href="`tel://${contacts[0].email}`"
										target="_blank">
										{{ contacts[0].email }}
									</a>
								</li>
								<li
									class="links-list__item"
									v-if="contacts.length">
									<div class="social">
										<a
											v-if="contacts[0].facebook"
											:href="contacts[0].facebook"
											class="facebook">
										</a>
										<a
											:href="contacts[0].instagram"
											v-if="contacts[0].instagram"
											class="instagram">
										</a>
										<a
											:href="contacts[0].linkedIn"
											v-if="contacts[0].linkedIn"
											class="linkedIn">
										</a>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-section">
			<div class="container py-2">
				<a
					href="https://beeronix.com"
					target="_blank">
					<div class="beetronix"></div>
				</a>
				<div class="copy-rights">
					All Rights Reserved Copyrights&copy;{{
						new Date().getFullYear()
					}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MainFooter',
	data() {
		return {
			aboutValue: null,
			contacts: [],
		};
	},
	mounted() {
		this.$store.dispatch('contacts/fetchAll').then((res) => {
			this.contacts = res;
		});
		this.$store.dispatch('aboutus/fetchAll').then((res) => {
			this.aboutValue = res.find((e) => e.key === 'about').value;
		});
	},
};
</script>
<style lang="scss" scoped>
.bottom-section {
	background-color: black;
}
* {
	font-size: 0.9rem;
}
.social * {
	font-size: 1em !important;
}
.brand-image {
	min-width: 150px;
	max-width: 150px;
	margin-right: 80px;
	@media (max-width: 1400px) {
		min-width: 130px;
		max-width: 130px;
		margin-right: 30px;
	}
	@media (max-width: 1200px) {
		min-width: 100px;
		max-width: 100px;
		margin-right: 30px;
	}
	@media (max-width: 576px) {
		margin: 0 auto 20px;
	}
}
</style>
<style lang="scss" scoped>
@use 'sass:list';
@use 'sass:map';
@import '@/style/style.scss';

$center-for-mobile: 576px;
$part-margin-x: 10px;
$bottom-section-justify-content: space-between;

%list-title {
	padding: 5px;
	font-weight: bold;
	margin-bottom: 5px;
	text-transform: capitalize;
}
%list-item {
	padding: 5px;
	line-height: 1em;
	margin-bottom: 20px;
	text-transform: capitalize;
}
%list-item-hover {
	color: $sec-color;
}

$page-links-col: 1;
$categories-col: 2;
$contact-us-col: 1;

$parts: (
	'main': (
		'order': 1,
		'width': 45%,
		'widths': (
			992px: 100%,
			768px: 100%,
			576px: 100%,
		),
	),
	'page-links': (
		'order': 5,
		'width': 15%,
		'widths': (
			992px: 40%,
			768px: 100%,
			576px: 100%,
		),
	),
	'categories': (
		'order': 5,
		'width': 0%,
		'widths': (
			992px: 0%,
			768px: 50%,
			576px: 100%,
		),
	),
	'contact-us': (
		'order': 3,
		'width': 25%,
		'widths': (
			992px: 50%,
			768px: 50%,
			576px: 100%,
		),
	),
	'spacer-1': (
		'order': 2,
		'width': 8%,
		'widths': (
			992px: 0%,
			768px: 0%,
			576px: 0%,
		),
	),
	'spacer-2': (
		'order': 4,
		'width': 7%,
		'widths': (
			992px: 0%,
			768px: 0%,
			576px: 0%,
		),
	),
	'spacer-3': (
		'order': 5,
		'width': 0%,
		'widths': (
			992px: 0%,
			768px: 0%,
			576px: 0%,
		),
	),
);

@each $part-name, $part-prop in $parts {
	.page-footer__part--#{$part-name} {
		order: map.get($part-prop, 'order');
		width: map.get($part-prop, 'width');
	}
	@each $breakPx, $width in map.get($part-prop, 'widths') {
		@media (max-width: $breakPx) {
			.page-footer__part--#{$part-name} {
				width: $width;
			}
		}
	}
}

/****************/
.page-footer {
	display: flex;
	flex-wrap: wrap;
	@if $center-for-mobile {
		@media (max-width: $center-for-mobile) {
			justify-content: center;
			text-align: center;
		}
	}
	&__part {
		margin: $part-margin-x 0;
		padding: 0 5px;
		&--spacer-1,
		&--spacer-2,
		&--spacer-3 {
			padding: 0;
		}
	}
}
.links {
	&-title {
		@extend %list-title;
	}
	&-list {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		&__item {
			@extend %list-item;
			& a:hover {
				@extend %list-item-hover;
				cursor: pointer;
			}
		}
	}
}
.links--page-links {
	.links-list__item {
		width: calc(100% / $page-links-col);
	}
}
.links--categories {
	.links-list__item {
		width: calc(100% / $categories-col);
	}
}
.links--contact-us {
	.links-list__item {
		width: calc(100% / $contact-us-col);
	}
}
.bottom-section {
	> div {
		display: flex;
		align-items: center;
		justify-content: $bottom-section-justify-content;
		* {
			font-size: 0.85em;
		}
	}
}
</style>
<style>
.about *:not(:first-child) {
	display: none;
}
</style>
